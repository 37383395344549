<section id="our_service w3-container">
  <div class="container w3-animate-bottom">
    <div class="row text-center" >
        <div class="col-md-6 col-md-offset-3 topheadingpage " id= "heading-text" style="margin-top: 10%;">
          <h2>{{contact.textfirsthead}}</h2>
             
              <!-- <hr class= "full">
              <br/> -->
        </div>
      </div>
   
     

      <div class="main_content">
        <div class="row">
          <div class="col-lg-4 ">
              <div class="location ">
                  <span class="locationinner firstloc" style="font-size: 25px;padding-left: 4px;">  <i  style="padding-right:10px;" class="fa fa-map-marker" aria-hidden="true"></i></span>
              </div>
              <div class="textali " style="text-align:center">
                  <p style="padding-top: 8px;">{{contact.textfirstco}}</p>
                  <p>{{contact.textsecondco}}</p>
                  <p>{{contact.textthirdco}}</p>
              </div>
          </div>
          <div class="col-lg-4 ">
              <div class="location ">
                  <span class="locationinner">  <i  style="padding-right:10px;" class="fa fa-phone" aria-hidden="true"></i></span>
              </div>
              <div class="textali " style="text-align:center ">
                  <p   style="padding-top: 8px;">{{contact.textfourthco}}</p>

              </div>
          </div>
          <div class="col-lg-4 ">
              <div class="location ">
                  <span class="locationinner" style="font-size: 25px;">  <i  style="padding-right:10px;" class="fa fa-envelope" aria-hidden="true"></i></span>
              </div>
              <div class="textali " style="text-align:center ">
                  <p  style="padding-top: 8px;"><a style="color:#fff !important;margin-top: 5px;" href="mailto:enquiry@novussol.com">{{contact.textfifthco}}</a></p>

              </div>
          </div>
      </div>
      <div id="map" style="margin-top: 20px;">
          <iframe  [src]="urlSafe" class="mapclass " frameborder="0" style="border:0" allowfullscreen></iframe>
      </div>
    </div>	<!-- main_content -->
  </div>	<!-- container -->
</section>