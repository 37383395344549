<!-- <div class="col-md-6 col-md-offset-3">
  <h1>Home</h1>
  
  <button (click)="openModal('first')">Open Modal 1</button>
  <button (click)="openModal('custom-modal-2')">Open Modal 2</button>
</div>

<modal id="first">
  <div class="modal">
      <div class="modal-body">
          <h1>A Custom Modal!</h1>
        
          <button (click)="closeModal('first');">Close</button>
      </div>
  </div>
  <div class="modal-background"></div>
</modal>

<modal id="custom-modal-2">
  <div class="modal">
      <div class="modal-body">
          <h1 style="height:1000px">A Tall Custom Modal!</h1>
          <button (click)="closeModal('custom-modal-2');">Close</button>
      </div>
  </div>
  <div class="modal-background"></div>
</modal> -->

<section id="our_service" class="w3-container">
  <div class="container containeradded w3-animate-left">
    
    <div class="row text-center" >
          <div class="col-md-6 col-md-offset-3 wow animated zoomInDown" id= "heading-text" style="margin-top: 10%;">
            <h2 style="color: #fff;">{{solutions.solutionsheader}}</h2>
                <!-- <p>We are lucky to service people like you</p>
                <hr class= "full"> -->
                <br/>
          </div>
        </div>
      <div class="main_content" style="padding: 0px;">
        <div class="services">
            <div class="row" id="servicefirst">
              <!-- <h3 style="color:#fff;text-align:center " >Golf Platform</h3> -->
              <div class="col-sm-4 " >
              
                <div class="service-icon ">
                 
                  <img id ="imgoe" [src]="solutions.image1"  style="cursor: pointer;" />
                  <!-- <i class="fa fa-code fa-5x"></i> -->
                </div>
              </div>
              <div class="col-sm-8">
                <div class="about-service" >
                  <h4 style="color:#fff;text-align:left;font-weight: bold;;text-decoration: underline; " >Golf Platform</h4>
                  <!-- <h2 class="text-center">Golf Platform</h2> -->
                  <p class="" >
                    A completely open and web based  <a href="https://onlinegolfapp.com" target="blank" >Golf Platform</a> which makes the golf operations easy for the operations team, at the same time enhances the experience of your members and guests. Can Integrate with Existing Club ERP for syncing Membership and Wallet data
                  </p>
                  <div *ngFor="let list of solutions.listofitems">
                    
                  <p style="font-weight: bold;margin: 0px !important;"> {{list.title}}</p>
                    <!-- Handicap Management</p> -->
                  <!-- <p style="font-weight: bold;margin: 0px !important">Tournament Managemnet</p>
                  <p style="font-weight: bold;margin: 0px !important">Tee Time Management</p> -->
                  </div>
                  <!-- <ul>
                  <li style="color:#fff;font-weight: bold;list-style-type: disc !important;">Handicap Management</li>
                </ul> -->
                </div>
              </div>	<!-- col-sm-4 -->
             
            </div>	<!-- row -->

            <div class="row" style="margin-top: 40px;">
              <div class="col-sm-4  service servicecommo" id="servicesecod">
                <div class="service-icon ">
                  
                  <img  [src]="solutions.image2"   />
                  <!-- <i class="fa fa-desktop fa-5x"></i> -->
                </div>
              </div>
              <div class="col-sm-8">
                <div class="about-service">
                  <h4 style="color:#fff;text-align:left;font-weight: bold;;text-decoration: underline; " >Mobile Applications</h4>
                <!-- <p>Mobile Apps Development</p> -->
                  <p class="">
                    We take into mobile app optimization and lead it through stages of testing and implementation adding features requested by clients through feedback mechanism.Our app development company helps businesses and individuals establish their presence on any modern device and platform – mobile, web, wearables, and TV.
                  </p>
                </div>
              </div>	<!-- col-sm-4 -->
            </div>
            <div class="row" style="margin-top: 40px;">
              <div class="col-sm-4   service servicecommo" id="servicethree">
                <div class="service-icon">
                  <!-- <h3 class="text-center">web Applications</h3> -->
                  <img   [src]="solutions.image3"  />
                  <!-- <i class="fa fa-th fa-5x"></i> -->
                </div>
              </div>
              <div class="col-sm-8">
                <div class="about-service">
                  <h4 style="color:#fff;text-align:left;font-weight: bold;text-decoration: underline; " >Web Applications</h4>
                 <!-- <p>Make a mark with our cutting-edge web application development
                </p> -->
                  <p class="">
                    Web application acts like the backbone of an enterprise and is extremely essential for supporting nearly every aspect of how online business is planned, executed and managed. Having the vast experience, our team of skillful developers helps you plan, discover & implement critical WebApps that have become a competitive asset for your online business processes.
                  </p>
                </div>
              </div>
            </div>
            
            </div>
            <div class="row" style="margin-top: 40px;">
            
              <div class="col-sm-4  service servicecommo" id="servicefour">
                <div class="service-icon ">
                  <!-- <h3 class="text-center">Social Media management </h3> -->
                  <img   [src]="solutions.image4" style="margin:50px"   />
                  <!-- <i class="fa fa-th fa-5x"></i> -->
                </div>
              </div>
              <div class="co-sm-8">
                <div class="about-service">
                  <h4 style="color:#fff;text-align:left;font-weight: bold;text-decoration: underline; " >Social Media management</h4>
                  <p class="">
                    Social media plays an integral part in the business landscape. With 3.2 billion people using social media around the world, and 11 new users every second, it’s safe to say the fad has turned into a global standard. And if you aren’t making social media a priority at this point in the game, you’re in trouble.We have expertise at handling social media activity, from responding to customer comments to developing strategy on how to achieve long term marketing goals by publishing original content.
                  </p>
                </div>
              </div>
              </div>
            </div>
           
    
              	<!-- col-sm-4 -->
            <!-- </div> -->
           
     
  </div>	<!-- container -->
</section>	


