<section id="" class="w3-container"  >
  <div class="container w3-animate-zoom">
    <div class="row text-center ">
      <div class="col-md-12 topheadingpage " id= "" style="margin-top: 10%;">
        <h2>{{home.textfirstho}}</h2>
           
            <!-- <hr class= "full">
            <br/> -->
      </div>
    </div>

    <div class="row " id="heading">
      <div >
        <!-- <h2 style="margin-top:15%;text-align: center;">{{home.textfirstho}}</h2> -->
        <img [src]="home.image" style="width:50%;display:block;margin:0 auto;"  />
        <!-- <p>{{home.textsecondho}}</p> -->
        <!-- <p>{{home.textthreeho}}</p> -->
        <!-- <p>{{home.textfourho}}</p> -->


    </div>
    </div>
   <div class="row">
    <!-- <h5 style="color: #fff;padding-top: 0px; text-decoration: underline;">{{home.workheadig}}</h5> -->
    <p  style="text-align: justify;"> {{home.textfirstwo}}</p>
    
    <p style="text-align: justify;">{{home.textthirdwo}} </p>

    <p> {{home.textfourthwo}} </p>

  

    <div *ngFor="let list of home.listofitems">

      <p class= "padded"style="font-weight: bold;color: #fff; font-size: 17px;margin-bottom: 5px;">
          {{ list.title }}
      </p>
    
    </div>
   </div>
    
  </div>
</section>
