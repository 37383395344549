import { Component } from '@angular/core';
import{Router, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription} from 'rxjs';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'novussite';
  subscription : Subscription;

  constructor(private router:Router){ }

  ngonInit(){
    this.subscription = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    )
    .subscribe(() => window.scrollTo(0,0));

  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  // ScrollIntoView(elem: string) {
  //   console.log(elem);
  //   document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start' });
  // }
}
