
  <div id="wrapper" class="backgimagewrapper">
    <div id="overlay-1">
      <div class="container w3-animate-zoom">
          <section id="starting">
        <div class="text-center starting-text">
          <h1 class="rene ">{{header.textfirst}}</h1>
          <p>{{header.textsecodabout}}</p>
       <!-- <p>{{header.textthirdabout}}</p>
       <p>{{header.textfourthabout}}</p> -->
        <button style="padding: 15px;margin-top:20px;border:none;font-size: 22px;background: #fff;border-radius: 4px;"> <a routerLink="/Home" (click)="getActiveTab('home')" [class.active]="activetab === 'home' "> Learn More</a></button> 
        </div>
        </section>
        </div>
        </div>
      </div>
   