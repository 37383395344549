import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  home={ 

    textfirstho:'string',
    textsecondho:'string',
    image:'image',
    textthreeho:'string',
    textfourho:'string',
    workheadig:'string',
    textfirstwo:'string',
    textsecondwo:'string',
    textthirdwo:'string',
    image2:'image',
    textfourthwo:'string',
    listofitems:[],
    textlastwo:'string'
    
  };

  constructor(private config: ConfigService) { }


  ngOnInit() {
    this.home= this.getHome();
    // return this.header;
  }
  getHome(){
    return this.config.getconfig().home;
  }

}
