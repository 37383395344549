import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { ConfigService } from '../config.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
 

  contact={ 
    textfirsthead:'Contact us',
    textfirstco:'5/1, Penthouse 01',
    textsecondco:'6th Floor, Rich Homes Apartment',
    textthirdco:'Richmond Road, Bengaluru, Karnataka.',
    textfourthco:'Tel: +91 9845540799',
    textfifthco:'saday@novussol.com',
  
  
  };
  name = 'Set iframe source';
  url: string = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0956226747107!2d77.59784541482196!3d12.965732690859703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15d1575610e1%3A0x623c9342a16779b0!2sJaaga!5e0!3m2!1sen!2sin!4v1555481728672!5m2!1sen!2sin"; 
 

  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }

  // constructor(private config: ConfigService) { }


  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    
    // this.contact= this.getContact();
    // return this.header;
  }
  // getContact(){
  //   return this.config.getconfig().contact;
  // }


}
