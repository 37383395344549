import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';


@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']

  
})
export class SolutionsComponent implements OnInit {

  solutions={ 
    solutionsheader:'string',
    image1:'image',
    image2:'image',
    image3:'image',
    image4:'image',
    listofitems:[],
   
  
  };

  constructor(private config: ConfigService) { }


  ngOnInit() {
    this.solutions= this.getSolutions();
    
  }
  getSolutions(){
    return this.config.getconfig().solutions;
  }


}
