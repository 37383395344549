<section id= "footer" class= "main-footer">
    <div class= "row">
      <div class= "logo text-center">
        <h1>Renessa</h1>
      </div>
    </div>
    <div class= "row">
      <div class= "copyright text-center">
        <p> © 2014 Technext. Designed and Developed by <a href="http://themewagon.com"><span class= "theme">Themewagon</span></a></p>
      </div>
    </div>
  </section><!-- footer -->