
    <!-- <section id="starting"> -->
      <!-- <div> -->
      <app-navigation></app-navigation>
      <!-- <app-home></app-home>
      <app-solutions></app-solutions>
      <app-work></app-work>
      <app-about></app-about>
      <app-clients></app-clients>
      <app-contact></app-contact> -->
    

    <router-outlet></router-outlet>
    <!-- </div> -->
  <!-- </section> -->
  
    <!-- </div>
    </div> -->

     <!-- <app-header></app-heasder>
    <app-banner></app-banner>
    <app-home></app-home>
    <app-solutions></app-solutions>
    <app-work></app-work>
    <app-about></app-about>
    <app-contact></app-contact>
    <app-footer></app-footer> -->


    <!-- <div id="bottom" class="bottom text-center">
          <a href="#about"><i class="ion-ios7-arrow-down"></i></a>
      </div> -->
  <!-- </div>overlay-1
</div>	wrapper		 -->
  
<!-- About Us -->
	<!-- about us -->

<!-- Our service -->
	<!-- our_service -->

<!-- Our Team -->
 <!-- team -->

<!-- Portfolio -->
 

<!-- Price-Table -->
 
<!-- contact -->

  
<!-- footer -->


<!-- js -->
  