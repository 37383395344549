import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // header={
  //   textfirst:'Novus Solutions',
  //   textsecond:'Welcome to our World'
  // };
  header={ 

    textfirst:'string',
    textsecond:'string',
    textfirstabout:'string',
    textsecodabout:'string',
 
   
  };

  constructor(private config: ConfigService) { }
  activetab = 'home';
  ngOnInit() {
    this.header= this.getHeader();
    // return this.header;
  }
  getHeader(){
    return this.config.getconfig().header;
  }
  getActiveTab(tabname: string){
    this.activetab = tabname;
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 50); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    })
  }

}
