import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  ImagePath = 'www.golfshire.com';

  clients={ 
    clietheadig:'string',
    imageoe:'image',
    imagetwo:'image',
    imagethree:'image',
    imagefour:'image',
    imagefive:'image',
    imagesix:'image',
    imageseve:'image',
    imageeight:'image',
    imagenine:'image',
    imageten:'image'
  };
  ImageClick() {
    this.ImagePath = "https://www.w3schools.com/";
}

  constructor(private config: ConfigService) { }


  ngOnInit() {
    this.clients= this.getClients();

  }
  getClients(){
    return this.config.getconfig().clients;
  }


}
