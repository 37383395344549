import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';



import { AppComponent } from './app.component';

import { OwlModule } from 'ngx-owl-carousel';
import { HomeComponent } from './home/home.component';
import { SolutionsComponent } from './solutions/solutions.component';
// import { WorkComponent } from './work/work.component';

import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { AppRoutingModule } from './/app-routing.module';
import { NavigationComponent } from './navigation/navigation.component';
import { ClientsComponent } from './clients/clients.component';
import { ConfigService } from './config.service';
import { ModalComponent } from './_directives';
import { ModalService } from './_services';

// import { TrackScrollDirective } from './track-scroll.directive';



@NgModule({
  declarations: [
    
    AppComponent,
    HomeComponent,
    SolutionsComponent,
    // WorkComponent,
 
    ContactComponent,
    HeaderComponent,
    FooterComponent,
  
    NavigationComponent,
  
    ClientsComponent,
    ModalComponent,
    // TrackScrollDirective
   
  ],
  imports: [
   
    BrowserModule,
    AppRoutingModule,
    OwlModule
  ],
  providers: [
    ConfigService,
    ModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
