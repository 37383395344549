<section id="our_service" class="w3-container">
  <div class="container w3-animate-right">
    <div class="row text-center" >
          <div class="col-md-6 col-md-offset-3 topheadingpage " id= "heading-text" style="margin-top: 10%;">
            <h2>{{clients.clietheadig}}</h2>
               
             
                <br/>
          </div>
        </div>

      <div class="main_content clientmain" style="background: #fff;margin: 50px;">
        <div class="servicesadded" style="padding: 20px;">
            <div class="row ten-columns" >
              <div class="col-sm-2 service">
                
                <div class="about-service">
                  <!-- <h3 class="text-center">Web Application</h3> -->
                  <a target="_blank" href="http://www.golfshire.com"> <img [src]="clients.imageoe"    style="width:70%;display:block;margin:0 auto;cursor: pointer;"  />
                  </a>
                </div>
              </div>	<!-- col-sm-4 -->
              <div class="col-sm-2 service">
                
                <div class="about-service">
                  <!-- <h3 class="text-center">Web Application</h3> -->
                  <a target="_blank" href="https://www.kga.in/">   <img [src]="clients.imagesix" style="width:70%;display:block;margin:0 auto;"  /></a>
                </div>
              </div>	<!-- col-sm-4 -->
              <div class="col-sm-2 service">
               
                <div class="about-service">
                  <!-- <h3 class="text-center">Graphics Designing</h3> -->
                  <a target="_blank" href="https://eagletongolf.eagletonindia.com/"> <img [src]="clients.imagethree" style="width:70%;display:block;margin:0 auto;"  /></a>
                </div>
              </div>
              <div class="col-sm-2 service">
               
                <div class="about-service">
                  <!-- <h3 class="text-center">Web Application</h3> -->
                  <a target="_blank" href="http://anahalifecare.com/">  <img [src]="clients.imagenine" style="width:70%;display:block;margin:0 auto;"  /></a>
                </div>
              </div>
              <div class="col-sm-2 service">
               
                <div class="about-service">
                  <!-- <h3 class="text-center">Web Application</h3> -->
                  <a target="_blank" href=" https://ismrititek.com/">  <img [src]="clients.imagefour" style="width:50%;display:block;margin:0 auto;"  /></a>
                </div>
              </div>
              		<!-- col-sm-4 -->
            </div>	<!-- row -->
            <div class="row ten-columns" style="margin-top: 30px;">
              <div class="col-sm-2 service">
               
                <div class="about-service">
                  <!-- <h3 class="text-center">Game Development</h3> -->
                  <a >   <img [src]="clients.imagefive" style="width:70%;display:block;margin:0 auto;padding-top: 12px;"  /></a>
                </div>
              </div>	<!-- col-sm-4 -->
              <div class="col-sm-2 service">
                <!-- <div class="service-icon text-center">
                  <i class="fa fa-lightbulb-o fa-5x"></i>
                </div> -->
                <div class="about-service">
                  <!-- <h3 class="text-center">Creative Ideas</h3> -->
                  <a target="_blank" href="https://www.bio-gen.in/">  <img [src]="clients.imagetwo" style="width:70%;display:block;margin:0 auto;"  /></a>
                </div>
              </div>	<!-- col-sm-4 -->
              <div class="col-sm-2 service">
                <!-- <div class="service-icon text-center">
                  <i class="fa fa-stack-overflow fa-5x"></i>
                </div> -->
                <div class="about-service">
                  <!-- <h3 class="text-center">Support</h3> -->
                 <a target="_blank" href="http://www.prestigeaugusta.com/"> <img [src]="clients.imageseve" style="width:70%;display:block;margin:0 auto;"  /></a>
                </div>
              </div>
              
              <div class="col-sm-2 service">
                <!-- <div class="service-icon text-center">
                  <i class="fa fa-gamepad fa-5x"></i>
                </div> -->
                <div class="about-service">
                  <!-- <h3 class="text-center">Game Development</h3> -->
                  <a target="_blank" href="https://zionhills.in/">  <img [src]="clients.imageeight" style="width:70%;display:block;margin:0 auto;"  /></a>
                </div>
              </div>
              <div class="col-sm-2 service">
                <!-- <div class="service-icon text-center">
                  <i class="fa fa-gamepad fa-5x"></i>
                </div> -->
                <div class="about-service">
                  <!-- <h3 class="text-center">Game Development</h3> -->
                  <a target="_blank" href="http://www.tsgacademy.in/">  <img [src]="clients.imageten" style="width:70%;display:block;margin:0 auto;"  /></a>
                </div>
              </div><!-- col-sm-4 -->
            </div>	<!-- row -->
      </div>	<!-- services -->
    </div>	<!-- main_content -->
  </div>	<!-- container -->
</section>