export const configuration={

header:{
    textfirst:'Novus Solutions',
    textsecond:'Welcome to our World',
    textfirstabout:' About us',
     
    textsecodabout:'Novus Solutions specialises in custom software development and technology consulting. We automate complex business processes through enterprise web and mobile solutions. We also help our customer manage their Digital presence through Websites and Social Media Management. We have pioneered custom solutions that have become central components in our client’s business success. Our industry expertise spans Soprts, Real Estate, Pharma and Logistics. Get in touch with us if you need sophisticated solutions rapidly and on budget.',

    


  },
  home:{
      textfirstho:'Managing your Digital Presence through Technology',
      textsecondho:'Novus Solutions specialises in custom software development and technology consulting. We automate complex business processes through enterprise web and mobile solutions.We also help our customer manage their Digital presence through Websites and Social Media Management.',
      image:'assets/img/landing.png',
      textthreeho:'We also help our customer manage their Digital presence through Websites and Social Media Management.',
    
      textfourho:'We have pioneered custom solutions that have become central components in our client’s business success. Our industry expertise spans Soprts, Real Estate, Pharma and Logistics. Get in touch with us if you need sophisticated solutions rapidly and on budget.',
      
      workheadig:'Work we do',
      textfirstwo:'In the digital age, it is absolutely essential for your business to have everything online and connected. Whether it’s a website, a web application platform, a social media page or a combination of all three, getting your company online and everything connected will reap major benefits. Even if your company does not conduct business online, customers and potential customers are expecting to see you online. If they don’t see you there, you could be losing out on the opportunity to increase your customer base and get the word out about your business.',
      textsecondwo:'A business that maintains a website is more likely to receive traffic just by simply being there. ',
      textthirdwo:'At Novus Solution we have deep expertise in managing you Digital Presence through a comprehensive package that includes, Websites, Portals (Internal or External members portal), Web Applications and Social Media Management.',
      image2:'assets/img/photois.png',
      textfourthwo:'We can help you with -',
      listofitems:[
       { title:'Custom Web and Application Development'},
       {title:'Modernizing existing Applications'},
       {title:'Social Media Management'},
       {title:'Moving your applications and business to cloud'}
      ],
      textlastwo:'We have success stories in the industry verticals of Sports, Natural Nutrition, Logistics and Real Estate. Get in touch with us and you will not be dissatisfied.'
  
    }, 
  work:{
    workheadig:'Work we do',
    textfirstwo:'In the digital age, it is absolutely essential for your business to have everything online and connected. Whether it’s a website, a web application platform, a social media page or a combination of all three, getting your company online and everything connected will reap major benefits. Even if your company does not conduct business online, customers and potential customers are expecting to see you online. If they don’t see you there, you could be losing out on the opportunity to increase your customer base and get the word out about your business.A business that maintains a website is more likely to receive traffic just by simply being there. And according to Google and Nielsen, 73 percent of mobile searches trigger additional action and conversion while 55 percent of purchase-related conversions occur within one hour of an initial mobile search.',
    textsecondwo:'A business that maintains a website is more likely to receive traffic just by simply being there. And according to Google and Nielsen, 73 percent of mobile searches trigger additional action and conversion while 55 percent of purchase-related conversions occur within one hour of an initial mobile search.',
    textthirdwo:'At Novus Solution we have deep expertise in managing you Digital Presence through a comprehensive package that includes, Websites, Portals (Internal or External members portal), Web Applications and Social Media Management.',
    image:'assets/img/photois.png',
    textfourthwo:'We can help you with -',
    listofitems:[
     { title:'Custom Web and Application Development'},
     {title:'Modernizing existing Applications'},
     {title:'Social Media Management'},
     {title:'Moving your applications and business to cloud'}
    ],
    textlastwo:'We have success stories in the industry verticals of Sports, Natural Nutrition, Logistics and Real Estate. Get in touch with us and you will not be dissatisfied.'

  },
  about :{
    textfirstabout:' About us',
     
    textsecodabout:'Novus Solutions specialises in custom software development and technology consulting. We automate complex business processes through enterprise web and mobile solutions.',

    textthirdabout:'We also help our customer manage their Digital presence through Websites and Social Media Management.',

    textfourthabout:'We have pioneered custom solutions that have become central components in our client’s business success. Our industry expertise spans Soprts, Real Estate, Pharma and Logistics. Get in touch with us if you need sophisticated solutions rapidly and on budget.'


  },
  clients:{
    clietheadig:'Our Clients',
    imageoe:'assets/img/golflogo.png',
    imagetwo:'assets/img/Biogenlogo.png',
    imagethree:'assets/img/eaglelogo.png',
    imagefour:'assets/img/ismritilogo.png',
    imagefive:'assets/img/jrlogo.jpg',
    imagesix:'assets/img/logokga.png',
    imageseve:'assets/img/preslogo.png',
    imageeight:'assets/img/zh-logo.png',
    imagenine:'assets/img/anahalogo.png',
    imageten:'assets/img/academy.jpg'

  },
  solutions:{
    solutionsheader:'Solutions that we Provide',
    image1:'../assets/img/golfplatform.png',
   
    listofitems:[
      {title:'Handicap Management'},
      {title:'Tournament Managemnet'},
      {title:'Tee Time Managemnet'},
     
     ],
     image2:'../assets/img/unnamed.png',
     image3:'../assets/img/webre.png',
     image4:'../assets/img/socialre.png',
  //   textof1:' A completely open and web based  <a ng-href="https://onlinegolfapp.com" >Golf Platform</a> which makes the golf operations easy for the operations team, at the same time enhances the experience of your members and guests. Can Integrate with Existing Club ERP for syncing Membership and Wallet data',
  }
  // contact:{
  //   textfirstco:'5/1, Penthouse 01',
  //   textsecondco:'6th Floor, Rich Homes Apartment',
  //   textthirdco:'Richmond Road, Bengaluru, Karnataka 560025',
  //   textfourthco:'Tel: +91 9845540799',
  //   textfifthco:'saday@novussol.com',
  //   imageco:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0956226747107!2d77.59784541482196!3d12.965732690859703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15d1575610e1%3A0x623c9342a16779b0!2sJaaga!5e0!3m2!1sen!2sin!4v1555481728672!5m2!1sen!2sin',
  
  // }
  
}