

<section id= "navigation">
    <nav class="navbar navbar-default navbar-fixed-top" role="navigation">
        <div class="container-fluid">
          <div class="navbar-header">
              <button type="button" class="navbar-toggle" (click)="isCollapsed = !isCollapsed" style="margin-top: 20px;margin-right: 20px;" >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <!-- <span class="ion-navicon"></span> -->
              </button>
              <!-- <a class="navbar-brand" href="#">Novus Solutions</a> -->

              <div class="nav-logo" style="display: flex;align-items: center;

              ">
                <span> <img src="../assets/img/novuslogo.png" height="60" alt="logo" /></span>

          <p class="navbar-brand addedtextcl" style="color:#000; margin-top: 14px;margin-left: 0px;">Novus Solutions</p>
        </div>

          </div>
          <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1"  [attr.aria-expanded]="!isCollapsed" [ngClass]="{collapse: isCollapsed}" >
            <ul class="nav navbar-nav navbar-right" (click)="isCollapsed = !isCollapsed">

             
                <li><a routerLink="/" routerLikActive="active" >Home</a></li>
                <li><a routerLink="/Solutions" routerLikActive="active">Solutions</a></li>
                <!-- <li><a routerLink="/Work"  (click)="getActiveTab('work')" [class.active]="activetab === 'work' ">Work</a></li> -->
                <!-- <li><a routerLink="/About" (click)="getActiveTab('about')" [class.active]="activetab === 'about' ">About</a></li> -->
                <li><a routerLink="/Clients" routerLikActive="active">Clients</a></li>
                <li><a routerLink="/Contact" routerLikActive="active">Contact</a></li>
               
           
            </ul>
          </div>	<!-- collapse navbar-collapse -->
        </div>	<!-- container-fluid -->
    </nav>	<!-- navbar -->
  </section>	<!-- #navigation -->



