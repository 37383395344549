import { NgModule } from '@angular/core';
import { RouterModule,Routes } from '@angular/router';


import { HomeComponent } from './home/home.component';
import { SolutionsComponent } from './solutions/solutions.component';
// import { WorkComponent } from './work/work.component';

import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ClientsComponent } from './clients/clients.component';

import { CommonModule } from '@angular/common';


const routes: Routes = [
  { path: '', redirectTo:'/Header',pathMatch:'full'},
  { path: 'Header', component: HeaderComponent },
  { path: 'Home',    component: HomeComponent },
  { path: 'Solutions', component: SolutionsComponent },
  // { path: 'Work',   component: WorkComponent },

  { path: 'Contact', component: ContactComponent },
  { path: 'Footer', component: FooterComponent },
  { path: 'Clients', component: ClientsComponent },
 


];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations:[],
  exports: [RouterModule]
})
export class AppRoutingModule { }
